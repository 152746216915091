//- Variables
@import "../../abstracts/variables"
@import "../../abstracts/mixins"

// C01

.c01Noty
	padding: 0
	.c01Login
		padding-top: 0
		padding-bottom: 0
		// padding-top: $spacer6
		padding-bottom: $spacer3
		// margin-top: -$spacer3
		background-color: $rojo8
		.c01LoginCont
			+bx
			width: 100%
			max-width: 1280px
			margin: 0 auto
			padding: 0 $spacer2
			.c01LoginForm
				color: #fff
				width: 100%
				max-width: 520px
				margin: 0 auto
				
			.c01LoginFloat
				+bx
				+borderRadius(6px)
				color: #fff
				width: 100%
				max-width: 520px
				margin: 0 auto 0
				padding: $spacer2
				background-color: $gris8
				border-top: $amarillo6 10px solid
				overflow: hidden
				-webkit-box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				-moz-box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				position: relative
				top: $spacer
				h3, h2 ,.h5
					text-align: center
				.c01LoginFloatText
					+flex
					+flexDir(column)
					+justifyCont(space-between)
					margin-top: $spacer2
					.c01LFTCont
						+flex
						+flexDir
						margin-top: -($spacer3 /2)
						p
							margin-top: $spacer3 / 2
						.price
							+font-size(32px)
						.val
							+font-size(14px)
					.c01LFTbtn
						width: 100%
						margin-top: $spacer
						[class*="btn"]
							width: 100%
				&.animated
					animation: fadeInUp 1s both 0.5s
			// .c01LoginFloatLogin
			// 	@extend .c01LoginFloat
+mediaMin640
	.c01Noty
		.c01Login
			.c01LoginCont
				.c01LoginForm
					h3, h2, .h4
						+font-size(22px)
	
// +mediaMin760
// 	.c01Noty
// 		.c01Login
// 			.c01LoginCont
// 				.c01LoginFloat
					
+mediaMin960
	.c01Noty
		.c01Login
			padding-bottom: 0
			.c01LoginCont
				+flex
				+justifyCont(space-between)
				position: relative
				.c01LoginForm
					width: calc(100% - 400px)
					max-width: initial
					margin: 0
					
				.c01LoginFloat
					+flex
					+flexDir(column)
					+justifyCont(center)
					position: absolute
					top: -250px
					// top: -$spacer5
					right: $spacer2
					width: 380px
					// height: calc(100% + 50px)
					height: auto
					margin: 0
					margin-top: 0
					border-top: 0
					border-left: $amarillo6 10px solid
					min-height: 150px
					h3, h2 ,.h5
						text-align: left
					.c01LoginFloatText
						
						.c01LFTbtn
							width: 100%
							margin-top: $spacer2
					&.c01LoginFloatLogin
						top: -120px

		&.onlyC01Noty
			padding: $spacer4 0
			.c01Login
				.c01LoginCont
					.c01LoginFloat
						top: -70px
// +mediaMin1102
	
+mediaMin1280
	.c01Noty
		.c01Login
			.c01LoginCont
				.c01LoginForm
					width: calc(65% - 20px)
				
				.c01LoginFloat
					width: 35%
					height: auto
					top: -170px
					

					.c01LoginFloatText
						+flexDir(row)
						.c01LFTCont
							+bx
							width: calc(100% - 150px)
							padding-right: $spacer2
						.c01LFTbtn
							width: 150px
							margin-top: 0
					&.c01LoginFloatLogin
						top: -100px
	
+mediaMin1500
	.c01Noty
		.c01Login
			.c01LoginCont
				max-width: 1440px
				.c01LoginForm
					width: calc(66% - 40px)
				.c01LoginFloat
					width: 34%
					min-height: 135px