//- Variables
@import "../../abstracts/variables"
@import "../../abstracts/mixins"

.c01LoginWrap
	padding: 0
	.c01Login
		// padding-top: $spacer3
		padding-top: $spacer6
		padding-bottom: $spacer2
		// margin-top: 0
		margin-top: -$spacer3
		background-color: $rojo8
		.c01LoginCont
			+bx
			width: 100%
			max-width: 1280px
			margin: 0 auto
			padding: 0 $spacer2
			.c01LoginForm
				color: #fff
				width: 100%
				max-width: 520px
				margin: 0 auto
				h3, h2, .h4
					text-align: center
					margin-bottom: $spacer2
					span
						+font-size(16px)
						font-family: $fontPrin
						font-weight: 400
						display: block
						width: 100%
						margin-top: $spacer0
				form
					
					.c01LoginFormRight
						margin-top: $spacer2
					fieldset
						input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="date"], input[type="tel"], select, textarea
							border-color: #fff
						[class*="btn"]
							width: 100%
						+ fieldset
							margin-top: $spacer2
				
						&.c01LoginFormLeftPass
							position: relative
							input
								padding-right: 28px
							i
								color: black
								position: absolute
								right: 10px
								top: 12px
								+font-size(16px)
					p
						text-align: center
						margin-top: $spacer2
						[class*="textLink"]
							color: #fff
							&:after
								background-color: #fff
			.c01LoginFloat
				display: none
				+bx
				+borderRadius(6px)
				color: #fff
				width: 100%
				max-width: 520px
				margin: $spacer2 auto 0
				padding: $spacer2
				// background-color: $gris8
				// border-top: $amarillo6 10px solid
				overflow: hidden
				// -webkit-box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				// -moz-box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				// box-shadow: 0px 4px 8px 0px rgba(45,45,45,0.5)
				
+mediaMin640
	.c01LoginWrap
		.c01Login
			.c01LoginCont
				.c01LoginForm
					h3, h2, .h4
						+font-size(22px)                    

+mediaMin960
	
	.c01LoginWrap
		.c01Login
			padding-bottom: $spacer3
			.c01LoginCont
				+flex
				+justifyCont(space-between)
				position: relative
				.c01LoginForm
					width: calc(100% - 400px)
					max-width: initial
					margin: 0
					h3, h2, .h4
						text-align: left
						span
							+font-size(22px)
							font-family: $fontSec
							font-weight: 600
							display: inline
					form
						.c01LoginFormLeft
							+flex
							+justifyCont(space-between)
							&.c01LoginFormLeftPass
								position: relative
								i
									color: black
									position: absolute
						.c01LoginFormRight
							+flex
							+justifyCont(space-between)
							+alignItem(center)
						fieldset
							width: calc(50% - 10px)
							+ fieldset
								margin-top: 0
							
						p
							text-align: left
							width: calc(50% - 10px)
							margin-top: 0
				.c01LoginFloat
					+flex
					+flexDir(column)
					+justifyCont(center)
					position: absolute
					top: -$spacer5
					right: $spacer2
					width: 380px
					height: calc(100% + 50px)
					margin: 0
					margin-top: 0
					border-top: 0
					// border-left: $amarillo6 10px solid
					


+mediaMin1280
	.c01LoginWrap
		.c01Login
			.c01LoginCont
				.c01LoginForm
					width: calc(65% - 20px)
					form
						+flex
						+alignItem(center)
						+justifyCont(space-between)
						.c01LoginFormLeft
							width: calc(45% - 10px)
						.c01LoginFormRight
							width: calc(55% - 10px)
							margin-top: -3px
							fieldset
								width: 160px
							p
								width: calc(100% - 180px)
				.c01LoginFloat
					width: 35%
					height: auto
					

+mediaMin1500
	.c01LoginWrap
		.c01Login
			.c01LoginCont
				max-width: 1440px
				.c01LoginForm
					width: calc(66% - 40px)
				.c01LoginFloat
					width: 34%
					min-height: 135px    	    	                        